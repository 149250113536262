.footer {
    padding: 20px;
    background-color: #111;
}

.footer__links {
    flex: 1;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    transition: all ease 0.5s;
    justify-content: center;
    padding: 20px 0;
}

.footer__links::-webkit-scrollbar {
    display: none;
  }

.footer__links p {
    padding: 0 10px;
    cursor: pointer;
}

.footer__details {
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer__details p {
    padding-bottom: 5px;
    opacity: 0.7;
}

@media (max-width: 799px) {
    .footer__links {
        flex: 1;
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
        transition: all ease 0.5s;
        justify-content: left;
    }

    .footer__links p {
        font-size: 0.9rem;
    }

    .footer__details p {
        font-size: 0.9rem;

    }
}