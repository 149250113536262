.audioplayer {
    display:flex;
    flex:2;
    padding:20px;
    justify-content:space-between;
    align-items:center;
    border: solid 1px lightgrey;
    width:100%;
    border-radius:50px;
    background-color:#111;
    margin:auto 0;
}

.audioplayer__container {
    display: flex;
}

.audioplayer__track {
    display:flex; 
}

.audioplayer__track img {
    width:35px;
    height:35px;
    border-radius:5px;
    justify-content:center;
    align-items:center;
    margin:auto 0;
}

.audioplayer__track__info {
    margin-left:10px; 
}

.audioplayer__track__info h3 {
    font-size:1rem;
    color:white;
    margin:0;
    font-weight:200;
}

.audioplayer__track__info p {
    font-size:0.8rem;
    color:gray;
    margin:0;
}

.controls {
    display:flex;
    padding:0 20px;
}

.controls__prev {
    display:flex;
    justify-content:center;
    align-items:center;
    margin:0;
    cursor: pointer;
}

.controls__prev img {
    width:25px;
    height:25px;
}

.controls__next {
    display:flex;
    justify-content:center;
    align-items:center;
    margin:0;
    cursor: pointer;
}

.controls__next img {
    width:25px;
    height:25px;
}

@media (max-width: 799px) {
    .audioplayer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin: 0;
        border-radius:25px;
    }

    .audioplayer__container {
        padding: 10px 0;
    }

    .controls {
        display:flex;
        padding:0 5px;
    }
}