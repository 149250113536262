* {
  margin: 0;
  color: white;
  font-family: Lato, sans-serif;
}

.politicspromise__banner {
  position: relative;
  width: 100%;
  height: 300px;
}

.politicspromise__content {
  align-items: left;
  text-align: left;
  padding: 40px 80px 20px 80px;
  width: 50%;
}

.politicspromise__content h1 {
  color: white;
  font-size: 2.4rem;
  padding: 20px 0;
  font-weight: 700;
}

.politicspromise__back {
  display: flex;
  padding-bottom: 20px;
  opacity: 0.7;
  align-items: top;
}

.politicspromise__back__img {
  filter: invert(1);
}

.politicspromise__back__msg {
  color: white;
  font-size: 1rem;
}

.politicspromise__description {
  padding: 40px 80px;
  font-size: 1.2rem;
  line-height: 2rem;
}

.politicspromise__form__copyright {
  padding: 40px;
  justify-content: center;
  text-align: center;
}

.politicspromise__form__copyright p {
  font-size: 1rem;
  opacity: 0.8;
  padding-bottom: 10px;
}

.politics {
  padding: 40px 80px;
  z-index: 99;
}

.politics__container {
  display: flex;
  flex-direction: column;
}

.politics__item {
  display: flex;
  padding: 40px 0;
}

.politics__item h3 {
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 400;
}

.politics__item > span {
  display: flex;
  font-size: 1rem;
  color: purple;
  padding: 0 10px;
  align-items: center;
}

.politics__answer {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.politicspromise__maintext {
  padding: 40px 80px;
}

.politicspromise__maintext p {
  font-size: 1.2rem;
  line-height: 2rem;
  padding-bottom: 20px;
}

.politicspromise__maintext h2 {
  font-size: 1.4rem;
  line-height: 2.6rem;
  padding-bottom: 20px;
}

.politicspromise__maintext h3 {
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 700;
  padding-bottom: 20px;
  color: purple;
  margin: 20px 0;
}

.politicspromise__maintext h4 {
  font-size: 1.2rem;
  line-height: 2rem;
  color: purple;
  padding-bottom: 20px;
}

.politicspromise__maintext ul {
  padding: 0 0 20px 20px;
}

.politicspromise__maintext ul li {
  padding-bottom: 20px;
  font-size: 1.2rem;
  line-height: 2rem;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 799px) {
  .politicspromise__banner {
    position: relative;
    width: 100%;
    height: 350px;
  }

  .politicspromise__content {
    align-items: center;
    text-align: center;
    /* padding: 80px 80px 0 80px; */
    width: 55%;
  }

  .politicspromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
  }

  .politics {
    padding: 20px;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
