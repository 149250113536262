* {
  font-family: Lato, sans-serif;
}

.typewriter-text__mobile {
  display: none;
}

.welcome {
  width: auto;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.98); */
  font-family: Lato, sans-serif;
}

.welcome__container {
  display: flex;
  flex-direction: column;
}

.welcome__callcenter {
  display: flex;
  flex: 1;
  justify-content: right;
  align-items: center;
  padding: 60px;
}

.welcome__callcenter img {
  width: 40px;
  height: 100%;
  justify-content: right;
  align-items: center;
  text-align: right;
  cursor: pointer;
  background-color: #111;
  padding: 10px;
  border-radius: 20px;
}

.welcome__callcenter img:hover {
  transform: scale(1.05);
  transition: all 0.5s ease;
}

.welcome__img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome__img img {
  width: 280px;
  height: 100%;
}

.welcome h1 {
  font-size: 3.4rem;
  color: white;
  margin: 40px 0 20px 0;
  justify-content: center;
  text-align: center;
}

/* .welcome__store img {
  cursor: pointer;
} */

.welcome p {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: lighter;
  text-align: center;
  color: white;
  opacity: 0.8;
  width: fit-content;
  margin: 60px 100px 20px 100px;
  justify-content: center;
  text-align: center;
}

.welcome__scrolldown {
  cursor:pointer;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 40px;
}

.welcome__scrolldown svg path {
  fill: white;
}

.welcome__scrolldown:hover svg path {
  fill: purple;
}

.welcome__form__info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
}

.welcome__form__info ul {
  display: flex;
  padding: 0 20px;
}

.welcome__form__info ul li {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.welcome__form__info ul li input {
  padding: 10px 20px;
  background-color: #111;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  margin: 10px 0;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  width: 100%;
  font-size: 1rem;
}

.welcome__form__info ul li input::placeholder {
  opacity: 1;
  font-size: 1rem;
}

.welcome__form__info ul select {
  padding: 10px 20px;
  background-color: #111;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  margin: 10px 0;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  width: 100%;
  font-size: 1rem;
}

.welcome__form__info ul select option {
  background-color: white;
  color: #111;
}

.welcome__form__info__submit {
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #111;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.welcome__form__info__submit:hover {
  background-color: purple;
  transition: all 0.5s ease;
  color: white;
}

.welcome__form__copyright {
  padding-top: 40px;
}

.welcome__form__copyright p {
  font-size: 1rem;
  opacity: 0.8;
}

.welcome__form__sent {
  padding: 40px;
  justify-content: center;
  text-align: center;
}

.welcome__form__sent h2 {
  font-size: 1.4rem;
  font-weight: 400;
}

/* Style for the main content section */
.main-content {
  position: relative;
  width: 100%;
  height: 400vh; /* Adjust the height as needed */
  overflow: hidden;
}

.main-content__nav {
  display:flex;
  padding:20px;
  justify-content:space-between;
  z-index: 99;
}

.main-content__nav__logo {
  justify-content:left;
  align-items:center;
  margin:auto 0;
}

.main-content__nav__logo img {
  width:200px;
  height:100%;
  padding:0 20px;
}

.main-content__nav__storeicons {
  justify-content:right;
  align-items:center;
  margin:auto 0 auto 10px;
}

.main-content__nav__storeicons img {
  width:120px;
  cursor: pointer;
  pointer-events: auto;
}

.background-scene {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  will-change: background; /* Improve performance for background animations */
}

.phone-overlay img {
  position: fixed;
  bottom:0;
  left: 60%;
  transform: translateX(-50%);
  z-index: 2;
  width: 400px;
  opacity: 1;
}

.mobile-screen {
  background-size: cover;
  background-position: left;
  z-index: 1;
  position: fixed;
  bottom: 105px;
  left: 58.8%;
  transform: translateX(-53%);
  width: 200px;
  height: 420px;
  border-radius:20px;
}

.typewriter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.typewriter-container {
  display:flex;
  align-items:center;
  margin:auto 0;
  z-index: 99;
}

.typewriter-text {
  display:flex;
  color:white;
  width:500px;
  justify-content:center;
  align-items:center;
  padding:20% 0 20% 80px;
  font-size:1.6rem;
  line-height:2.4rem;
  font-weight:100; 
}

.typewriter-text p {
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent line breaks */
  animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
  color:white;
  padding:40px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-right: 2px solid transparent; /* Create a blinking cursor */
  }
  50% {
    border-right: 2px solid #fff; /* Blinking cursor color */
  }
}

.typewriter-text__headphone {
  display: flex;
  border: solid 1px white;
  border-radius: 50px;
  background-color: #111;
  padding: 20px 10px;
  width: 220px;
  justify-content: center;
  align-items: center;
  margin: 0 80px;
  cursor: pointer;
}

.typewriter-text__headphone:hover {
  border: solid 1px purple;
  background-color: purple;
  transition: all 0.5s ease;
}

.typewriter-text__headphone img {
  width: 35px;
  height: 100%;
  margin-right: 10px;
}

.additional {
  display:flex;
  width:auto;
  justify-content:space-between;
  align-items:center;
  margin:20px 40px;
}

.additional__button {
  border: solid 1px lightgrey;
  width:100%;
  color:white;
  padding:25px 10px;
  justify-content:center;
  align-items:center;
  border-radius:20px;
  background-color:#111;
  font-size:1rem;
  font-weight:400;
  cursor:pointer;
}

.additional__button:hover {
  border: solid 1px purple;
  background-color: purple;
  transition: all 0.5s ease;
}

@media (max-width: 799px) {
  .typewriter-text {
    display: none;
  }

  .welcome {
    height: 100%;
    padding: 40px 0;
  }

  .welcome__img img {
    width: 180px;
    height: 100%;
  }
  
  .welcome h1 {
    font-size: 1.8rem;
    color: white;
    margin: 40px 0 20px 0;
  }
  
  .welcome p {
    display: none;
    /* font-size: 1rem;
    line-height: 1.6rem;
    font-weight: lighter;
    text-align: center;
    color: white;
    opacity: 0.8;
    width: auto;
    padding: 0 20px; */
  }

  .welcome__form__info ul {
    flex-direction: column;
  }

  .welcome__form__info ul li input {
    margin: 2px 0;
  }

  .welcome__form__info ul select {
    margin: 2px 0;
  }

  .welcome__form__info__submit {
    margin: 5px 0;
    width: 100%;
  }

  .welcome__scrolldown {
    margin: 20px auto;
  }

  .welcome__callcenter {
    display: flex;
    flex: 1;
    justify-content: right;
    align-items: center;
    padding: 0;
  }

  .main-content__nav__logo {
    justify-content:center;
    align-items:center;
    margin:auto auto;
  }

  .main-content__nav__storeicons {
    margin:10px;
  }

  .main-content__nav__storeicons img {
    width:120px;
  }

  .phone-overlay img {
    position: fixed;
    bottom:0;
    left: 60%;
    transform: translateX(-50%);
    z-index: 2;
    width: 300px;
    opacity: 1;
  }

  .mobile-screen {
    background-size: cover;
    background-position: top;
    z-index: 1;
    position: fixed;
    bottom: 70px;
    left: 56.5%;
    transform: translateX(-50%);
    width: 150px;
    height: 327px;
    border-radius:20px;
  }

  .typewriter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .typewriter-container {
    display:flex;
    align-items:center;
    margin:auto 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }
  
  .typewriter-text__mobile {
    display: flex;
    padding: 20px;
    justify-content: center;
    text-align: center;
  }

  .additional {
    display:flex;
    flex-direction: column;
    width:auto;
    justify-content:center;
    align-items:center;
    margin:20px;
  }

  
}