* {
  margin: 0;
  color: white;
  font-family: Lato, sans-serif;
}

.faqpromise__banner {
  position: relative;
  width: 100%;
  height: 300px;
}

.faqpromise__content {
  align-items: left;
  text-align: left;
  padding: 40px 80px 20px 80px;
  width: 50%;
}

.faqpromise__content h1 {
  color: white;
  font-size: 2.4rem;
  padding: 20px 0;
  font-weight: 700;
}

.faqpromise__back {
  display: flex;
  padding-bottom: 20px;
  opacity: 0.7;
  align-items: top;
}

.faqpromise__back__img {
  filter: invert(1);
}

.faqpromise__back__msg {
  color: white;
  font-size: 1rem;
}

.faqpromise__form__copyright {
  padding: 40px;
  justify-content: center;
  text-align: center;
}

.faqpromise__form__copyright p {
  font-size: 1rem;
  opacity: 0.8;
}

.faq {
  padding: 40px 80px;
  z-index: 99;
}

.faq__container {
  display: flex;
  flex-direction: column;
}

.faq__item {
  display: flex;
  padding: 40px 0;
}

.faq__item h3 {
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 400;
}

.faq__item > span {
  display: flex;
  font-size: 1rem;
  color: purple;
  padding: 0 10px;
  align-items: center;
}

.faq__answer {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 799px) {
  .faqpromise__banner {
    position: relative;
    width: 100%;
    height: 350px;
  }

  .faqpromise__content {
    align-items: center;
    text-align: center;
    /* padding: 80px 80px 0 80px; */
    width: 55%;
  }

  .faqpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
  }

  .faq {
    padding: 20px;
  }
}

/* Laptop */
@media (max-width: 992px) {
  /* .faqpromise__content {
    align-items: center;
    text-align: center;
    padding: 80px;
    width: 50%;
  }

  .faqpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
  }

  .faqpromise__body__definition {
    text-align: center;
    padding: 0 50px;
  }

  .faqpromise__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .faqpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .faqpromise__body {
    padding: 60px 20px;
    text-align: left;
    line-height: 1.8rem;
  }

  .faqpromise__body__offer {
    display: flex;
    padding: 40px 80px;
  }

  .faqpromise__body__offer__img img {
    display: none;
  }

  .faqpromise__body__followers {
    display: flex;
    padding: 40px 80px;
  }

  .faqpromise__body__followers__img img {
    display: none;
  }

  .faqpromise__body__together__img img {
    display: none;
  }

  .faqpromise__body__together {
    display: flex;
    padding: 40px 80px;
  }

  .faqpromise__body__expectation {
    display: flex;
    padding: 40px 80px;
  }

  .faqpromise__body__expectation__img img {
    display: none;
  }

  .faqpromise__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }

  .faq {
    padding: 20px;
  } */
}

/* Desktop */
@media (max-width: 1200px) {
}
