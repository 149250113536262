* {
  margin: 0;
  color: white;
  font-family: Lato, sans-serif;
}

.clientservice__banner {
  position: relative;
  width: 100%;
  height: 35vh;
}

.clientservice__content {
  align-items: left;
  text-align: left;
  padding: 40px 80px 80px 80px;
  width: 50%;
}

.clientservice__content h1 {
  color: white;
  font-size: 2.4rem;
  padding: 20px 0;
  font-weight: 700;
}

.clientservice__back {
  display: flex;
  padding-bottom: 20px;
  opacity: 0.7;
  align-items: top;
}

.clientservice__back__img {
  filter: invert(1);
}

.clientservice__back__msg {
  color: white;
  font-size: 1rem;
}

.clientservice__banner__content {
  padding:40px 0;
}

.clientservice__banner__content__logo img {
  max-width:250px;
}

.clientservice__banner__content h1 {
  font-size: 2.4rem;
}

.clientservice__body {
  padding: 80px;
  text-align: left;
  line-height: 1.8rem;
}

.clientservice__body__closure {
  text-align: center;
  padding: 80px;
}

.clientservice__body__closure h4 {
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: 400;
}

.clientservice__body__closure p {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  padding: 40px 0;
}

.clientservice__form__info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.clientservice__form__info ul {
  padding: 0 20px;
}

.clientservice__form__info ul li {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.clientservice__form__info ul li input {
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  margin: 10px 0;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  width: 100%;
  font-size: 1rem;
}

.clientservice__form__info ul li input::placeholder {
  opacity: 1;
  font-size: 1rem;
}

.clientservice__form__info__select p {
  margin: 0;
}

.clientservice__form__info__select select {
  padding: 10px 20px;
  background-color: #111;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  width: 100%;
  font-size: 1rem;
}

.clientservice__form__info__select select option {
  background-color: white;
  color: #111;
}

.clientservice__form__info__textarea textarea {
  /* padding: 10px 20px; */
  background-color: transparent;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  margin: 10px 0;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  width: 100%;
  font-size: 1rem;
}

.clientservice__form__info__textarea textarea::placeholder {
  opacity: 1;
  font-size: 1rem;
  padding-top: 10px;
}

.clientservice__form__info__submit {
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #111;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.clientservice__form__info__submit:hover {
  background-color: purple;
  transition: all 0.5s ease;
  color: white;
}

.clientservice__form__copyright {
  padding-top: 40px;
}

.clientservice__form__copyright p {
  font-size: 1rem;
  opacity: 0.8;
}

.clientservice__form__sent {
  padding: 40px;
  justify-content: center;
  text-align: center;
}

.clientservice__form__sent h2 {
  font-size: 1.4rem;
  font-weight: 400;
}

.clientservice__politics {
  display: flex;
  flex-direction: column;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.clientservice__politics p {
  cursor: pointer;
  padding-bottom: 20px;
  font-size: 1.2rem;
}

.clientservice__politics p:hover {
  color: purple;
  transition: all 0.5s ease;  
}

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 799px) {
  .clientservice__content {
    align-items: left;
    text-align: left;
    padding: 20px;
    width: auto;
  }

  .clientservice__content h1 {
    color: white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding: 20px 0;
  }

  .clientservice__body {
    padding: 40px;
    text-align: left;
    line-height: 1.8rem;
  }

  .clientservice__body__definition {
    text-align: center;
    padding: 0 20px;
  }

  .clientservice__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .clientservice__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: normal;
  }

  .clientservice__body {
    padding: 60px 20px;
    text-align: left;
    line-height: 1.8rem;
  }

  .clientservice__body__offer {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
  }

  .clientservice__body__offer div p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .clientservice__body__offer__img img {
    width: 100%;
    margin: 0;
    border-radius: 20px;
  }

  .clientservice__body__followers {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
  }

  .clientservice__body__followers div p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .clientservice__body__followers__img img {
    display: none;
  }

  .clientservice__body__together__img img {
    width: 100%;
    margin: 0;
  }

  .clientservice__body__together {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
  }

  .clientservice__body__together div p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .clientservice__body__expectation {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
  }

  .clientservice__body__expectation div p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .clientservice__body__expectation__img img {
    display: none;
  }

  .clientservice__body__closure {
    text-align: center;
    padding: 20px;
  }
  
  .clientservice__body__closure h4 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 400;
  }
  
  .clientservice__body__closure p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    padding: 40px 0;
  }
  
  .clientservice__form__info {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .clientservice__form__info ul {
    padding: 0 20px;
  }
  
  .clientservice__form__info ul li {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    width: 100%;
  }
  
  .clientservice__form__info ul li input {
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    border: solid 1px rgba(255, 255, 255, 0.5);
    margin: 10px 0;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    width: 100%;
    font-size: 1rem;
  }
  
  .clientservice__form__info ul li input::placeholder {
    opacity: 1;
    font-size: 1rem;
  }
  
  .clientservice__form__info__paybutton {
    display: flex;
    padding: 20px 40px;
  }
  
  .clientservice__form__info__paybutton button {
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #111;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  
  .clientservice__form__info__paybutton button:hover {
    background-color: purple;
    transition: all 0.5s ease;
    color: white;
  }
  
  .clientservice__form__copyright {
    padding-top: 40px;
  }
  
  .clientservice__form__copyright p {
    font-size: 1rem;
    opacity: 0.8;
  }
  
  .clientservice__form__sent {
    padding: 40px;
    justify-content: center;
    text-align: center;
  }
  
  .clientservice__form__sent h2 {
    font-size: 1.4rem;
    font-weight: 400;
  } 
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
